import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {


  function sendEmail() {
    try{
      axios.get(`https://api.innoestate.fr/emails/add?email=${document.querySelector('#emailInput').value}&app=autodevis`).then((res) => {
      // axios.get(`http://localhost:1000/emails/add?email=${document.querySelector('#emailInput').value}&app=autodevis`).then((res) => {
        alert('merci!');
      });
    }catch(e){
      console.error(e);
      alert(`une erreur s'est produite!`);

    }
  }


  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='first-section relative bg-gradient-white background-animate overflow-hidden'>
          <section>
            <div className='flex items-center justify-between px-8 py-5'>
              <div className='w-auto'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto mr-14'>
                    <a href='#'></a>
                  </div>
                  <div className='w-auto hidden lg:block'>
                    <ul className='flex items-center mr-10'>
                      <li className='font-heading mr-9 text-gray-900 hover:text-gray-700 text-lg'></li>
                      <li className='font-heading mr-9 text-gray-900 hover:text-gray-700 text-lg'></li>
                      <li className='font-heading mr-9 text-gray-900 hover:text-gray-700 text-lg'></li>
                      <li className='font-heading text-gray-900 hover:text-gray-700 text-lg'></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='w-auto'>
                <div className='flex flex-wrap items-center'>
                  <div className='w-auto hidden lg:block'>
                    <div className='flex flex-wrap'>
                      <div className='w-auto'></div>
                      <div className='w-auto'></div>
                    </div>
                  </div>
                  <div className='w-auto lg:hidden'>
                    <a href='#'>
                      <svg
                        className='navbar-burger text-gray-800'
                        width={51}
                        height={51}
                        viewBox='0 0 56 56'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          width={56}
                          height={56}
                          rx={28}
                          fill='currentColor'
                        />
                        <path
                          d='M37 32H19M37 24H19'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
              <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
              <nav className='relative z-10 px-9 py-8 bg-white h-full'>
                <div className='flex flex-wrap justify-between h-full'>
                  <div className='w-full'>
                    <div className='flex items-center justify-between -m-2'>
                      <div className='w-auto p-2'>
                        <a className='inline-block' href='#'>
                          <img
                            src='gradia-assets/logos/gradia-name-black.svg'
                            alt=''
                          />
                        </a>
                      </div>
                      <div className='w-auto p-2'>
                        <a className='navbar-burger' href='#'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6 18L18 6M6 6L18 18'
                              stroke='#111827'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col justify-center py-8 w-full'>
                    <ul>
                      <li className='mb-12'>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Features
                        </a>
                      </li>
                      <li className='mb-12'>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Solutions
                        </a>
                      </li>
                      <li className='mb-12'>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Resources
                        </a>
                      </li>
                      <li>
                        <a
                          className='font-heading font-medium text-lg text-gray-900 hover:text-gray-700'
                          href='#'
                        >
                          Pricing
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='flex flex-col justify-end w-full'>
                    <div className='flex flex-wrap'>
                      <div className='w-full'>
                        <button className='p-0.5 font-heading block w-full text-lg text-gray-900 font-medium rounded-10'>
                          <div className='py-2 px-5 rounded-10'>
                            <p>Login</p>
                          </div>
                        </button>
                      </div>
                      <div className='w-full'>
                        <button className='group relative p-0.5 font-heading block w-full text-lg text-gray-900 font-medium bg-gradient-cyan overflow-hidden rounded-10'>
                          <div className='absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full bg-gradient-cyan transition ease-in-out duration-500' />
                          <div className='py-2 px-5 bg-white rounded-lg'>
                            <p className='relative z-10'>Try for free</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </section>
          <div className='relative z-10 container mx-auto px-4'>
            <div className='flex flex-wrap items-center -m-6 pt-32 lg:pt-0'>
              <div className='w-full lg:w-1/2 p-6'>
                <div className='lg:max-w-xl'>
                  <h1 className='mb-8 font-heading text-8xl md:text-10xl text-gray-900 font-bold'>
                    Vos devis en automatique
                  </h1>
                  <p className='mb-10 text-gray-600 text-xl'>
                    Ne perdez plus de temps avec des logiciels complexes pour
                    des devis qui n'ont aucune garantie d'être acceptés.
                  </p>
                  <div className='flex flex-wrap -m-2.5 mb-20'>
                    <div className='w-auto p-2.5'>
                      <a href='#'></a>
                    </div>
                    <div className='w-auto p-2.5'>
                      <a href='#'></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-1/2 p-6'>
                <img
                  className='block mx-auto'
                  src='images/istockphoto-1181559738-612x612.jpg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-24 pb-32 overflow-hidden'>
          <div className='container mx-auto px-4 mb-12'>
            <h2 className='mb-6 max-w-xl mx-auto font-heading font-bold text-center text-6xl sm:text-7xl text-gray-900'>
              dictez votre chantier
            </h2>
            <p className='mb-16 max-w-lg mx-auto text-center text-gray-600 text-lg'>
              Pas besoin de taper des montagnes de texte.
              <br></br>
              L'application s'occupe de tout.
            </p>
            <div className='flex flex-wrap -m-8'>
              <div className='w-full md:w-1/3 p-8'>
                <a className='group' href='#'>
                  <div className='mb-9 overflow-hidden rounded-2xl'>
                    <img
                      className='transform hover:scale-110 w-full transition ease-out duration-500'
                      src='images/DALL-E-2023-08-04-14-20-52-Un-ouvrier-de-chantier-qui-modifie-un-parametre-sur-un-smartphone-Le-smartphone-doit-etre-image-en-images-transparentes-avec-du-relief-3D-comme-si-le.png'
                      alt=''
                    />
                  </div>
                  <h3 className='mb-4 font-heading font-bold text-gray-900 text-xl group-hover:underline'>
                    Paramétrez vos prestations
                  </h3>
                  <p className='mb-5 text-gray-600 text-base'>
                    Un paramétrage unique a l'installation de l'application
                    suffit pour que tous vos devis générés respectent votre
                    façon de travailler et de tarifer vos prestations.
                  </p>
                </a>
                <a
                  className='group inline-block font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden'
                  href='#'
                >
                  <div className='w-full transform -translate-x-0 group-hover:translate-x-full h-0.5 bg-gradient-cyan transition ease-in-out duration-500' />
                </a>
              </div>
              <div className='w-full md:w-1/3 p-8'>
                <a className='group' href='#'>
                  <div className='mb-9 overflow-hidden rounded-2xl'>
                    <img
                      className='transform hover:scale-110 w-full transition ease-out duration-500'
                      src='images/robot.png'
                      alt=''
                    />
                  </div>
                  <h3 className='mb-4 font-heading font-bold text-gray-900 text-xl group-hover:underline'>
                    l'IA à votre écoute
                  </h3>
                  <p className='mb-5 text-gray-600 text-base'>
                    Votre simple description du chantier permettra a notre
                    logiciel bousté par l'intelligence artificielle de générer
                    automatiquement votre devis.
                  </p>
                </a>
                <a
                  className='group inline-block font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden'
                  href='#'
                >
                  <div className='w-full transform -translate-x-0 group-hover:translate-x-full h-0.5 bg-gradient-cyan transition ease-in-out duration-500' />
                </a>
              </div>
              <div className='w-full md:w-1/3 p-8'>
                <a className='group' href='#'>
                  <div className='mb-9 overflow-hidden rounded-2xl'>
                    <img
                      className='transform hover:scale-110 w-full transition ease-out duration-500'
                      src='images/insurance-539659-1280.jpg'
                      alt=''
                    />
                  </div>
                  <h3 className='mb-4 font-heading font-bold text-gray-900 text-xl group-hover:underline'>
                    Une simple validation
                  </h3>
                  <p className='mb-5 text-gray-600 text-base'>
                    Une fois un devis généré, vous pouvez le valider et
                    l'envoyer automatiquement à votre client ou encore le mettre
                    en attente de correction et le modifier tranquilement une
                    fois à la maison.
                  </p>
                </a>
                <a
                  className='group inline-block font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden'
                  href='#'
                >
                  <div className='w-full transform -translate-x-0 group-hover:translate-x-full h-0.5 bg-gradient-cyan transition ease-in-out duration-500' />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-32 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='bg-white overflow-hidden rounded-10 shadow-5xl'>
              <div className='flex flex-wrap items-center -m-6'>
                <div className='w-full md:w-1/2 p-6'>
                  <img
                    className='relative -left-10'
                    src='images/istockphoto-1252675203-612x612.jpg'
                    alt=''
                  />
                </div>
                <div className='w-full md:w-1/2 p-12'>
                  <div className='md:max-w-md'>
                    <h2 className='mb-2.5 font-heading font-semibold text-4xl sm:text-5xl text-gray-900'>
                      inscrivez vous pour recevoir plus d'informations sur
                      l'application
                    </h2>
                    {/* <p className='mb-9 text-gray-700'>
                      faites partie des premiers a la tester
                    </p> */}
                    <p className='text-sm text-gray-900 mb-3'>Email</p>
                    <div className='flex flex-wrap -m-1.5'>
                      <div className='w-full md:flex-1 lg:w-auto p-1.5'>
                        <div className='p-px bg-gradient-cyan focus-within:ring-4 focus-within:ring-indigo-500 rounded-lg'>
                          <input id="emailInput"
                            className='w-full px-5 py-4 text-sm text-gray-500 placeholder-gray-500 bg-white outline-none rounded-md'
                            type='text'
                            placeholder='Entrer votre email'
                          />
                        </div>
                      </div>
                      <div className='w-full md:w-auto p-1.5'>
                        <button className='group relative font-heading py-4 px-9 block w-full md:w-auto text-base text-white font-medium bg-gray-900 overflow-hidden rounded-lg' onClick={sendEmail}>
                          <div className='absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full transition ease-in-out duration-500 bg-gradient-cyan' />
                          <p className='relative z-10'>Souscrire</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

